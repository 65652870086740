<template>
  <main>
    <div style="padding-left: 20px; padding-right: 20px">
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="Отзывы"
      />
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="На модерации"> </a-tab-pane>
        <a-tab-pane key="2" tab="Список отзывов"> </a-tab-pane>
      </a-tabs>
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    callback(key) {
      if (key == 1) {
        this.$router.push("pending");
      } else {
        this.$router.push("list");
      }
    },
  },
};
</script>
